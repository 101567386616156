export interface TransactionData {
    "nonelabels": null;
    "label_group": string;
    "location": string;
    "location_structured": {
        "address": string;
        "city": string;
        "state": string;
        "postcode": string;
        "country": string;
        "latitude": number;
        "longitude": number;
        "google_maps_url": string;
        "apple_maps_url":string;
        "store_number": string;
    },
    "logo": string;
    "merchant": string;
    "merchant_id": string;
    "person": null;
    "transaction_id": string;
    "website": string;
    "recurrence":string;
    "recurrence_group": null;
    "confidence": null;
    "transaction_type": null;
    "mcc": null;
    "intermediaries": [],
    "parent_tx": {
        "date": string;
        "amount": number;
        "entry_type": string;
        "iso_currency_code":string;
        "description": string;
        "account_holder_id": null;
        "account_holder_type": string;
        "country": null;
        "transaction_id": string;
        "mcc": null;
    };
    "returned_fields": [];
    "created_at": null;
    "error": null;
    "error_details": null;
    "labels": string[];
    "kwargs": {};
};

export enum TransactionCategories {
    MERCHANT = 'Merchant',
    SPEND_CATEGORY = 'Spend Category',
    CITY = 'City',
    STATE = 'State',
    RECURRENCE = 'Recurrence'
}

export enum UserTypes{
    HEAVY_OVERDRAFT_USER = "Heavy Overdraft User", 
    FRAUDSTER = "Fraudster", 
    BUDGET_CONCIOUS_USER = "Budget-Conscious User", 
    REWARD_SEEKER = "Reward Seeker",
    DEFAULT = "New User"
}
export enum PurchaseTypes{
    ONLINE_PURCHASE = "Online Purchase", 
    IN_STORE = "In-store"
}
export enum PaymentTypes{
    PAY_UTILITY_BILLS = "Pay utility bills", 
    PAY_CREDIT_CARD_BILLS = "Pay credit card bills", 
    PAY_RENT_OR_MORTGAGE = "Pay rent or mortgage"
}