import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { queryKeys } from "react-query/constants";


async function getDefaultIntentResponses() {
    const { data } = await axios.get(`https://pocexl.et.r.appspot.com/intents/Loyal Customer`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
    });
    return data;
}

export function useDefaultIntentResponses() {
    const { data } = useQuery({
        queryKey: [queryKeys.intentResponseData],
        queryFn: getDefaultIntentResponses,
        staleTime: 10000,
    });
    return data;
}

async function getPersonaBasedIntentResponses() {
    const { data } = await axios.get(`https://pocexl.et.r.appspot.com/intents/${sessionStorage.getItem('persona')}`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
    });
    return data;
}

export function usePersonaBasedIntentResponses() {
    const { data } = useQuery({
        queryKey: [queryKeys.personaIntentResponseData],
        queryFn: getPersonaBasedIntentResponses,
        staleTime: 10000,
    });
    return data;
}

async function getLlmVersion() {
    const { data } = await axios.get('https://sa-llm-deploy-service-gwipexb7fa-uc.a.run.app/openapi.json', {
        headers: {
          Authorization: "Bearer 00001111",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
    });
    return data;
}

export function useLlmVersion() {
    const { data } = useQuery({
        queryKey: [queryKeys.llmVersionData],
        queryFn: getLlmVersion,
        staleTime: 10000,
    });
    return data;
}

async function saveChats(storedChatPayload: any) {
    const { data } = await axios.post(
        'https://pocexl.et.r.appspot.com/transcripts/add',
        storedChatPayload, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
            },
        }
    )
    return data;
}

export function useSaveChats() {
    const saveChatMutation = useMutation({
        mutationFn: (payload: any) => saveChats(payload)
    })
    return saveChatMutation;
}

async function getUserDetails() {
    let access_token = sessionStorage.getItem('access_token');
    const { data } = await axios.get('http://10.253.0.151:8080/api/v1/user', {
        headers: {
          Authorization: `Bearer ${access_token}`,
          //Authorization: `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjExYzhiMmRmNGM1NTlkMjhjOWRlNWQ0MTAxNDFiMzBkOWUyYmNlM2IiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiU2hpdmFtIEJhaHVndW5hIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2RlZHVuLXNpbWJhbmstbmF0aXZlLXVpIiwiYXVkIjoiZGVkdW4tc2ltYmFuay1uYXRpdmUtdWkiLCJhdXRoX3RpbWUiOjE3MjUyODA1NDcsInVzZXJfaWQiOiJiVGx1a3Y4dUU3Z29JMzRvSGoxVzlPZWJRU20yIiwic3ViIjoiYlRsdWt2OHVFN2dvSTM0b0hqMVc5T2ViUVNtMiIsImlhdCI6MTcyNTI4MDU0NywiZXhwIjoxNzI1Mjg0MTQ3LCJlbWFpbCI6InNiYWh1Z3VuYUBvdXJvLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInNiYWh1Z3VuYUBvdXJvLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.wpMZGqQ3plTz5GkcfICbo4bCh4zmxsKSjXytS-IxLJBqYq187-dtvSyNkRu-Qx3zkVE0GAELxCJ3nZxDjT4u66nX9-ydI8Qxr71oBqwdXYUk3r-Bfss5BkaF9kn3dSjAWY16FOIUglqdSFHGPdhZ8VzEfWc7TV6YCQvHkAbYBw4iK41xMZ4HhjD0nIhhBe61LVHcX8w6WpZRHx5jAYILg96Bk7jZ8e5P3_-0wQxwhvK-B1CJldkpvK7kGci23_lP7uK3QrvzNABlqemVKdXuVoGXeldRyWas3mK711IPhq-Kd2kv5aMrqv23o8Ixj8x7_V8AYOaAdnj10tRf_P5ztQ`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
    });
    return data;
}

export function useUserDetails() {
    const { data } = useQuery({
        queryKey: [queryKeys.userData],
        queryFn: getUserDetails,
        staleTime: 10000,
    });
    return data;
}

async function createProfile(payload: any) {
    const { data } = await axios.post(
        'http://10.253.0.151:8085/personapi/v1/person',
        payload, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
            },
        }
    )
    return data;
}

export function useCreateProfile() {
    const data = useMutation({
        mutationFn: (payload: any) => createProfile(payload)
    })
    return data;
}

async function getAccountDetails(account_id: string) {
    let access_token = sessionStorage.getItem('access_token');
    const { data } = await axios.get(`http://10.253.0.151:8080/api/v1/financial-account?account-id=${account_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
        },
    });
    return data;
}

export function useGetAccountDetails(account_id: string) {
    const { data } = useQuery({
        queryKey: [queryKeys.getAccountDetails, account_id],
        queryFn: () => getAccountDetails(account_id),
        staleTime: 10000,
    });
    return data;
}

async function addBankAccount(payload: any) {
    let access_token = sessionStorage.getItem('access_token');
    const { data } = await axios.post(
        'http://10.253.0.151:8080/api/v1/financial-account',
        payload, 
        {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
            },
        }
    )
    return data;
}

export function useAddBankAccount(successHandler: (data: any)=> void) {
    const data = useMutation({
        mutationFn: (payload: any) => addBankAccount(payload),
        onSuccess: (data) => {
            console.log("Bank account added successfully!", data);
            successHandler(data);
            //show msg in chat -> Account linked successfully! How much do you want to transfer to your account from *******1479?
        },
        onError: (error: any) => {
            console.error("Error adding bank account:", error);
        }
    })
    return data;
}