import { cardActions, mainMenuActions, overdraftActions, rewardsActions } from "components/va-screen/actions";
import { useState } from "react";

export interface VAResponse {
  replies: VAResponseReply[];
  conversation_id?: string;
  intent?: string;
}

export interface ValueType {
  description?: string;
  transactions?: any[];
  subscribed_plan_response?: { subscription: any; plan: any };
  action? : () => void;
}

export interface VAResponseReply {
  id?: string;
  type:
    | 'TEXT'
    | 'LINK'
    | 'PDF'
    | 'TRANSACTIONS_TABLE'
    | 'FEE_TABLE'
    | 'MINI_TILE'
    | 'ACTION_BUTTON';
  value: ValueType;
  from?: 'bot' | 'user';
  intent?: string;
  conversation_id?: string;
  storedInDB: boolean;
}

export const useMockVirtualAssistantMessageResponse = (setWelcomeMsg: any, setUserTxt: React.Dispatch<React.SetStateAction<string>> ) => {
  const displayName = JSON.parse(localStorage.getItem('okta-token-storage') || '{}').idToken?.claims?.name.split(' ')[0] || 'User';
  const menu = mainMenuActions(setUserTxt, setWelcomeMsg);
  menu.shift();

  const introChats = [
    {
      from: 'bot',
      type: 'TEXT',
      value: {
        description:
          `Welcome <name>${displayName}<name> to the Dedun SimBank virtual assistant. How can I help you?`
      },
      conversation_id: '1',
      storedInDB: false
    },
    ...menu
  ];

  setWelcomeMsg(() => {
    return introChats;
  });
}
