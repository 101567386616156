import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { VirtualAssistant } from 'components/virtual-assistant/assistant';
import ChatIcon from '@mui/icons-material/Chat';
import { Button, Fab } from '@mui/material';
import './va-screen.scss';
import { useCallback, useEffect } from 'react';
import OliveLogo from 'images/olive-logo.png';
import variables from 'styles/constants.module.scss';
import { SaveTranscripts } from 'components/virtual-assistant/components/save-transcripts';
import { useAcpSessionStorage } from 'packages/use-storage';
import CachedIcon from '@mui/icons-material/Cached';
import { VAResponseReply, useMockVirtualAssistantMessageResponse } from 'components/virtual-assistant/api/send-message';
import { useLlmVersion } from 'apis/apis';
import { rebindActions } from './rebindActions';
import { arraysEqual } from 'utils/utils';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// export type ActionKey = 'CARDS_ACTION' | 'NOTIFICATIONS_ACTION' | 'REWARDS_ACTION' | 'OVERDRAFT_ACTION' | 'SECURITY_ACTION' | 'MENU_ACTION' | 'NONE';
// export interface ActionTypes {
//   CARDS_ACTION: () => void,
//   NOTIFICATIONS_ACTION: () => void,
//   REWARDS_ACTION: () => void,
//   OVERDRAFT_ACTION: () => void,
//   SECURITY_ACTION: () => void,
//   MENU_ACTION: () => void,
//   NONE: () => void
// }

// export const actionMap: ActionTypes = {
//   'CARDS_ACTION': () => {
//     console.log('Cards!!');
//   },
//   'NOTIFICATIONS_ACTION': () => {
//     console.log('Notifications!!');
//   },
//   'REWARDS_ACTION': () => {
//     console.log('Rewards!!');
//   },
//   'OVERDRAFT_ACTION': () => {
//     console.log('Overdraft!!');
//   },
//   'SECURITY_ACTION': () => {
//     console.log('Security!!');
//   },
//   'MENU_ACTION': () => {
//     console.log('Menu!!');
//   },
//   'NONE': () => {
//     console.log('NONE!!');
//   }
// };

export default function VAScreen() {
  const [open, setOpen] = React.useState(false);
  const [welcomeMsg, setWelcomeMsg] = React.useState<any[]>([]);
  
  const [enableSaveTranscripts, setEnableSaveTranscripts] = React.useState(false);
  const [storedChat, setStoredChat] = useAcpSessionStorage("chatai_history", welcomeMsg);
  const [chatsToSave, setChatsToSave] = React.useState<any[]>([]);
  const [llmVersion, setLlmVersion] = React.useState('');
  const [userTxt, setUserTxt] = React.useState("");

  useEffect(() => {
    if (!storedChat.length) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMockVirtualAssistantMessageResponse(setWelcomeMsg, setUserTxt);
    } else {
      setWelcomeMsg(storedChat);
    }
  }, []);

  useEffect(() => {
      setStoredChat((prev: VAResponseReply[]) => {
        prev = arraysEqual(prev, welcomeMsg) ? [] : prev;
        return [...prev, ...welcomeMsg];
      });
      rebindActions(storedChat, setWelcomeMsg, setUserTxt);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeMsg]);

  const getNewChatsToSave = useCallback(() => {
    return storedChat.filter((chat: VAResponseReply) => {
      return !chat.storedInDB
    })
  }, [storedChat]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    const newChatsToSave = getNewChatsToSave();
    setChatsToSave(newChatsToSave);
    if (newChatsToSave.length) setEnableSaveTranscripts(true);
    setOpen(false);
  };

  useEffect(()=> {
    if (!open) (document.getElementById('amazon-connect-chat-widget') as HTMLElement)?.remove();
  }, [open]);

  const clearChat = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMockVirtualAssistantMessageResponse(setWelcomeMsg, setUserTxt);
    setStoredChat([]);
    
    //setStoredChat(useMockVirtualAssistantMessageResponse(setWelcomeMsg, true));
    
  }

  //fetch llm version
  const versionJson = useLlmVersion();

  useEffect(() => {
    if (versionJson)
      setLlmVersion(versionJson.info.version);
  }, [versionJson])

  useEffect(() => {
    let timer = setInterval(()=> {
      if (!chatsToSave.length && open) {
        const newChatsToSave = getNewChatsToSave();
        setChatsToSave(newChatsToSave);
        if (newChatsToSave.length) setEnableSaveTranscripts(true);
      }
    }, 30000);

    return () => {
      clearTimeout(timer);
    };
  }, [open, getNewChatsToSave, chatsToSave]);

  console.log('storedChat', storedChat);

  return (
    <React.Fragment>
      <div className="chatbot-button-cnt">
        <Fab style={{backgroundColor: `${variables.primaryColor}`}} aria-label="chatbot" className="button-fab" onClick={handleClickOpen}>
          <ChatIcon style={{color: '#ffffff'}} fontSize="large" />
        </Fab>
      </div>
      {enableSaveTranscripts && storedChat && <SaveTranscripts storedChat={storedChat} chatsToSave={chatsToSave} setChatsToSave={setChatsToSave} setEnableSaveTranscripts={setEnableSaveTranscripts} llmVersion={llmVersion} />}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{backgroundColor: `${variables.primaryColor}`}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <img alt="logo" src={OliveLogo} width="100"/>
            <Button variant="outlined" style={{position:'absolute', right:'10px', borderColor:"#fff", color:'#fff', backgroundColor:`${variables.primaryColor}`}} startIcon={<CachedIcon />} onClick={clearChat}>Clear Chat</Button>
          </Toolbar>
        </AppBar>
        <VirtualAssistant storedChat={storedChat} setStoredChat={setStoredChat} llmVersion={llmVersion} userTxt={userTxt} setUserTxt={setUserTxt} setWelcomeMsg={setWelcomeMsg} />
      </Dialog>
    </React.Fragment>
  );
}