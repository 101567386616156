import {
  createElement,
  FC,
  Fragment,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./components/bubbles/assistant.scss";
import { ChatBubble } from "./components/bubbles/chat-bubble";
import {
  VAResponseReply,
  VAResponse,
} from "./api/send-message";
import { Form, FormSpy } from "react-final-form";
import { FormApi } from "final-form";
import { SendIcon } from "./assets/send-icon";
import { useMediaQuery } from "packages/use-media-query";

import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { FetchIntentResponses } from "./components/fetch-intent-responses";
import { LoaderScreen } from "components/loader-screen";
import { io, Socket } from 'socket.io-client';
import { isFundTransferRequest } from "utils/utils";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import React from "react";
import { PlaidDialogContent } from "components/plaid/plaid-dialog";
import { useGetAccountDetails } from "apis/apis";
import { mainMenuActions } from "components/va-screen/actions";
import { boolean } from "zod";


function isJSON(str: string) {
  if ( /^\s*$/.test(str) ) return false;
  str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
  str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']');
  str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
  return (/^[\],:{}\s]*$/).test(str);
}

const PlaidDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ErrorMesg = "Should be just a moment...";
const ErrorMesgServiceDown =
  "Sorry, I am experiencing some technical issues right now. Would you mind trying again later?";
export const VAssistant: FC<{ storedChat: any; setStoredChat: (value: any) => void; llmVersion: string; userTxt:string; setUserTxt: React.Dispatch<React.SetStateAction<string>>; setWelcomeMsg: React.Dispatch<React.SetStateAction<any[]>> }> = ({storedChat, setStoredChat, llmVersion, userTxt, setUserTxt, setWelcomeMsg}) => {
  const [openPlaid, setOpenPlaid] = React.useState(false);
  const plaidSuccessMsg = useRef<VAResponseReply | null>(null);
  
  const [noResponseRequired, setNoResponseRequired] = useState(false);
  const focusMe = useRef<null | HTMLBRElement>(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const [fetchIntentResponses, setFetchIntentResponses] = useState(false);
  const [allIntentsResponseJSON, setAllIntentsResponseJSON] = useState([]);

  const followUpCount = useRef(0);
  
  const [isRetrying, setIsRetrying] = useState(0);

  const chatReplies = useMemo(() => {
    //if (storedChat.length === 1) followUpCount.current = 0; //reset followUpCount if chat cleared
    return storedChat?.length !== undefined ? storedChat : [];
  }, [storedChat]);

  const handleClosePlaid = () => {
    setOpenPlaid(false);
    setNoResponseRequired(false);
  };

  const account_details = useGetAccountDetails("");
  const externalBanksLinked = account_details?.filter((val: any) =>  val.type === "external-bank");

  const setChat = useCallback(
    (response: VAResponse, append = true, isEndChat: boolean = false) => {
      const { conversation_id, intent, replies } = response;
      const modifiedReplies = replies.map((x) => ({
        ...x,
        conversation_id,
        intent,
      }));

      const newReplies = append
        ? [...chatReplies, ...modifiedReplies.map(denoteAsBot)]
        : [...chatReplies, ...modifiedReplies.slice(chatReplies.length)];

      if (isRetrying === 0 || isRetrying === 2) {
        setStoredChat(newReplies);
      }

      if (isRetrying === 1 && append && modifiedReplies[0].intent !== "FE") {
        setStoredChat(newReplies);
        setIsRetrying(0);
      }

      if (isRetrying === 2) {
        setIsRetrying(0);
      }

      if (isEndChat) {
        const menu = mainMenuActions(setUserTxt, setWelcomeMsg);
        menu.shift();
        setStoredChat((prev: any) => [...newReplies, ...menu]);
      }
    },
    [chatReplies, isRetrying, setStoredChat, setUserTxt, setWelcomeMsg]
  );

  const formRef = useRef<FormApi | null>(null);

  const proxySubmitUserText = () => submitUserText({ message: userTxt });
  const proxyRetrySubmit = () =>
    submitUserText({
      message: storedChat[storedChat?.length - 2]?.value,
    });

  //RASA impl..
    // let socket = useRef({} as Socket<any, any>);
    // useEffect(() => {
    //   socket.current = io("http://10.253.0.153:5005");

    //   // Listen for messages from Rasa
    //   socket.current.on('bot_uttered', (resp: any) => {
    //     const respText = isJSON(resp.text) ?  JSON.parse(resp.text).result : resp.text;
    //     console.log("bot-respText", respText);
    //     const responseMsg = {
    //       conversation_id: "",
    //       intent: "intent",
    //       replies: [ {
    //         from: "bot",
    //         type: "TEXT",
    //         value: {
    //           description: respText
    //         },
    //         conversation_id: "",
    //         storedInDB: false
    //       }]
    //     } as VAResponse;

    //     //push user input to chatreplies
    //     chatReplies.push({
    //       from: "user",
    //       type: "TEXT",
    //       value: "message",
    //       storedInDB: false
    //     });
    //     setChat(responseMsg);
    //   });

    //   // Open connection and initiate a session with Rasa
    //   socket.current.on('connect', () => {
    //     console.log('Connected to Rasa server');
    //     // Manually emit the session_confirm event with a unique session_id
    //     //const sessionId = 'user_session_' + Math.random().toString(36).substr(2, 9); // Generate unique session ID
    //     //socket.current.emit('session_confirm', { session_id: sessionId });
    //     //console.log('Session confirmed with ID:', sessionId);
    //   });

    //   // socket.current.on('session_request', (session: any) => {
    //   //   console.log('Session ID request received from Rasa:', session);
        
    //   //   // Emit the session_id back to Rasa
    //   //   // You can generate your own session ID, or use the one provided by Rasa
    //   //   socket.current.emit('session_confirm', { session_id: session.session_id });
    //   // });

    //   // Error handling
    //   socket.current.on('connect_error', (error: any) => {
    //       console.error('Connection error:', error);
    //   });
    // }, []);

  //..RASA impl

  useEffect(() => {
    if (!openPlaid && sessionStorage.getItem('linked-account-number')) {
      const respText = "Your bank account is linked successfully! Please wait while we process your request...";
      const responseMsg = {
        conversation_id: "",
        intent: "intent",
        replies: [ {
          from: "bot",
          type: "TEXT",
          value: {
            description: respText
          },
          conversation_id: "",
          storedInDB: false
        }]
      } as VAResponse;

      plaidSuccessMsg.current = responseMsg.replies[0];
      
      console.log("==>>", chatReplies);
      // //push user input to chatreplies
      if (chatReplies[chatReplies.length - 1].type === "TEXT" &&  chatReplies[chatReplies.length - 1].value.toLowerCase() !== "yes") {
        chatReplies.push({
          from: "user",
          type: "TEXT",
          value: "Yes",
          storedInDB: false
        });
      }
  
      setChat(responseMsg);
      setNoResponseRequired(false);
      submitUserText({message: `load money from ${sessionStorage.getItem('linked-account-number')}`}, false, true);
      sessionStorage.removeItem('linked-account-number');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatReplies, openPlaid, setChat]);

  const submitUserText = async ({ message }: any, isUserMsg: boolean = true, fromPlaid: boolean = false) => {
    if (isUserMsg){
      setChat(
        {
          replies: chatReplies.concat({
            from: "user",
            type: "TEXT",
            value: message,
            storedInDB: false
          }),
        },
        false
      );
    }
    setUserTxt("");
    setIsDisabled(true);


    async function query(data: any) {
      const response = await fetch(
          "http://10.253.0.151:5005/webhooks/rest/webhook",
          {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data)
          }
      );
      const result = await response.json();
      return result;
    }
  
    // if (isFundTransferRequest(message)) {
    //   let respText ='';
    //   let responseMsg = {} as VAResponse;
    //    //check if account not linked and start plaid flow
    //   if (!externalBanksLinked.length) {
    //     respText = "There is no bank account linked. Do you want to link your bank account and proceed with the transfer?";
    //     responseMsg = {
    //       conversation_id: "",
    //       intent: "intent",
    //       replies: [ {
    //         from: "bot",
    //         type: "TEXT",
    //         value: {
    //           description: respText
    //         },
    //         conversation_id: "",
    //         storedInDB: false
    //       }, {
    //         "from": "bot",
    //         "type": "ACTION_BUTTON",
    //         "value": { 
    //           "description": "Yes=", "action": () => {
    //             console.log('111111');
    //             setUserTxt("Yes");
    //             setNoResponseRequired(true);
    //             setTimeout(()=> {
    //               document.getElementById('chat-submit')?.click();
    //             }, 0);
    //             setOpenPlaid(true);
    //           }
    //         }
    //       },
    //       {
    //         "from": "bot",
    //         "type": "ACTION_BUTTON",
    //         "value": { 
    //           "description": "No=", "action": () => {
    //             console.log('22222');
    //             setUserTxt("No")
    //             setNoResponseRequired(true);
    //             setTimeout(()=> {
    //               document.getElementById('chat-submit')?.click();
    //             }, 0);
    //           }
    //         }
    //       }]
    //     } as VAResponse;
    //   } else {
    //     respText = `There is ${externalBanksLinked.length} bank account linked. How do you want to proceed?`;
    //     responseMsg = {
    //       conversation_id: "",
    //       intent: "intent",
    //       replies: [ {
    //         from: "bot",
    //         type: "TEXT",
    //         value: {
    //           description: respText
    //         },
    //         conversation_id: "",
    //         storedInDB: false
    //       }, {
    //         "from": "bot",
    //         "type": "ACTION_BUTTON",
    //         "value": { 
    //           "description": "Link another account=", "action": () => {
    //             console.log('111111');
    //             setUserTxt("Link another account");
    //             setNoResponseRequired(true);
    //             setTimeout(()=> {
    //               document.getElementById('chat-submit')?.click();
    //             }, 0);
    //             setOpenPlaid(true);
    //           } 
    //         }
    //       },
    //       {
    //         "from": "bot",
    //         "type": "ACTION_BUTTON",
    //         "value": { 
    //           "description": "Continue with existing account(s)=", "action": () => {
    //             console.log('22222');
    //             setUserTxt("Continue with existing account(s)");
    //             setNoResponseRequired(true);
    //             setTimeout(()=> {
    //               document.getElementById('chat-submit')?.click();
    //             }, 0);
    //           }
    //         }
    //       }]
    //     } as VAResponse;
    //   }

    //   console.log(respText);

    //   //push user input to chatreplies
    //   chatReplies.push({
    //     from: "user",
    //     type: "TEXT",
    //     value: message,
    //     storedInDB: false
    //   });
    //   setChat(responseMsg);
    // } else if (noResponseRequired) {
    //   console.log('noResponseRequired');
    //    //push user input to chatreplies
    //    chatReplies.push({
    //     from: "user",
    //     type: "TEXT",
    //     value: message,
    //     storedInDB: false
    //   });
    //   setNoResponseRequired(false);
    // } else {
    //   //console.log('user_uttered', message, socket.current);
    //   //socket.current.emit('user_uttered', {sender: "12345", message: message });
    // }

    if (!noResponseRequired || fromPlaid) {
      query({
        "sender":JSON.parse(localStorage.getItem('okta-token-storage') || '{}')?.idToken?.claims?.nonce || "test_user23",
        "message": message,
        "metadata": {
          "user_id": JSON.parse(localStorage.getItem('okta-token-storage') || '{}')?.idToken?.claims?.nonce || "test_user",
          "financial_id": "",
          "token": `Bearer ${sessionStorage.getItem('access_token')}`
        }
      }).then((resp) => {
        let responses: VAResponse[] = [];
        resp.forEach((msg: any) => {
          const respText = msg.text;
          const responseMsg = {
            conversation_id: "",
            intent: "intent",
            replies: [ {
              from: "bot",
              type: "TEXT",
              value: {
                description: respText
              },
              conversation_id: "",
              storedInDB: false
            }]
          } as VAResponse;

          if (msg && Object.keys(msg).indexOf('buttons') > -1) {
            msg.buttons.forEach((button: any) => {
              responseMsg.replies.push({
                "from": "bot",
                "type": "ACTION_BUTTON",
                "storedInDB": false,
                "value": { 
                  "description": `${button.payload}=`, "action": () => {
                    setUserTxt(button.payload);
                    setTimeout(() => {
                      document.getElementById('chat-submit')?.click();
                    }, 0);
                    if (button.title.includes("Yes_connectbank")) {
                      setNoResponseRequired(true);
                      setOpenPlaid(true);
                    } else {
                      setNoResponseRequired(false);
                    }
                  } 
                }
              });
            });
          }
          responses.push(responseMsg);
        });

        
        //push user input to chatreplies
        if (isUserMsg) {
          chatReplies.push({
            from: "user",
            type: "TEXT",
            value: message,
            storedInDB: false
          });
        }

        let isEndChat = (userTxt === "End Chat") ? true : false;
        responses.forEach((resp: VAResponse, index: number) => {
          if (index > 0) responses[0].replies.push(...resp.replies);
        });
        if (plaidSuccessMsg.current) {
          responses[0].replies.unshift(plaidSuccessMsg.current);
          plaidSuccessMsg.current = null;
        }
        if (responses[0]) setChat(responses[0], true, isEndChat);
      });
    }
  };

  useEffect(() => {
    setIsDisabled(userTxt.length === 0);
  }, [userTxt]);

  const isSending = () => {
    return chatReplies?.slice(-1)[0]?.from === "user" ||
    chatReplies?.slice(-1)[0]?.value === ErrorMesg;
  }

  const onTextAreaKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    if (event.key === "Enter" && !event.shiftKey && userTxt.trim().length > 0) {
      formRef.current?.submit();
    }
  };
  useEffect(() => {
    const focusTimeout = setTimeout(() => {
      focusMe?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 200);

    return () => clearTimeout(focusTimeout);
  }, [chatReplies]);

  useEffect(() => {
    const chatElement = document.getElementById("chat");
    chatElement?.scrollTo(0, chatElement.scrollHeight);
  }, [chatReplies.length, chatReplies]);

  useEffect(() => {
    if (isRetrying === 1) {
      formRef.current?.submit();
    }
    if (isRetrying === 2) {
      setChat({
        replies: [
          {
            from: "bot",
            type: "TEXT",
            value: { description: ErrorMesgServiceDown },
            intent: "FE",
            storedInDB: false
          },
        ],
      });
    }
  }, [isRetrying, setChat]);

  const isDesktop = useMediaQuery(
    "(min-width: 600px), (orientation: landscape)"
  );
  const ContainerVA: FC = () => {
    return (
    <Fragment>
      {chatReplies.map((x: VAResponseReply, id: string) => (
        <ChatBubble {...x} id={id} key={id} />
      ))}
      {isSending() && <div className="DotTyping"></div>}
      <br />
      <br id="focus-me" ref={focusMe} />
    </Fragment>
  )};

  const isChatBot = window.location.href.indexOf("virtual-assistant") > -1;

  useEffect(() => {
    //Amazon connect widget
    let windowObj = window as { [key: string]: any };
    const loadAmazonConnect = () => {
      const script = document.createElement("script");
      script.src =
        "https://d1nv5i00u1m742.cloudfront.net/amazon-connect-chat-interface-client.js";
      script.async = true;
      script.id = "0e851621-f6ef-4802-8eb9-bed084dc3b14";
      document.getElementsByTagName("head")[0].appendChild(script);
      windowObj["amazon_connect"] =
        windowObj.amazon_connect ||
        function () {
          (windowObj.amazon_connect.ac =
            windowObj.amazon_connect.ac || []).push(arguments);
        };
    };

    const setAmazonConnectStyles = () => {
      windowObj.amazon_connect("styles", {
        iconType: "CHAT_VOICE",
        openChat: { color: "#ffffff", backgroundColor: "#C1272D" },
        closeChat: { color: "#ffffff", backgroundColor: "#C1272D" },
      });
    };

    const setAmazonConnectSnippetId = () => {
      windowObj.amazon_connect(
        "snippetId",
        "QVFJREFIakNnb2I4UUFhMXdNbmZJdnEvYXJSclVQTEk0eUtlQjYvUkZmQ2Q5NU1hSWdFYkcyc3RUaFBGMlY0RFhiN0xvYTg0QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNYllnb2s4WitnRERpd3ZHL0FnRVFnQ3MvZ0Q0TmNRbndqdWU3VFhUTlh3eFhvVEUzZXNhc1Y4ZkxlOTFhOHEvK1l4OE9jaStTZVFnVGhjMnM6Omg2NGh6UUlSdnkyQWd3VlRSTjRHU3QwRkZpVHM2UFV1Y1ZKSVBvTnZXSDlYbTFFRC9LV29nSldHaWpWYWlTVnFoY1o5Nk5wVlY5TXhycDdoR21WV3hqRXFoMllwUmNhdEZUTWlGbVR2T3p5ZGNxUXZGNXZYT2pudERyZHVJME9UeWVXYjRCaGkvaGQ3QktVWTZlM0NCMUEyZkF2RERqTT0="
      );
    };

    const setAmazonConnectSupportedMessagingContentTypes = () => {
      windowObj.amazon_connect("supportedMessagingContentTypes", [
        "text/plain",
        "text/markdown",
      ]);
    };

    const authenticateAmazonConnect = () => {
      windowObj.amazon_connect(
        "authenticate",
        function (callback: (token: string) => void) {
          const sign = require("jwt-encode");
          const expirationTime = Math.floor(Date.now() / 1000) + 10 * 60; // 10 minutes

          const payload = {
            sub: "0e851621-f6ef-4802-8eb9-bed084dc3b14",
            iat: Math.floor(Date.now() / 1000) * 60,
            exp: expirationTime,
          };

          const token = sign(
            payload,
            "Q67B31TysSwhIc0rlqn8jds0ptG65nlBhFe+Bv2HoVs="
          );
          callback(token);
        }
      );
    };
    loadAmazonConnect();
    setAmazonConnectStyles();
    setAmazonConnectSnippetId();
    setAmazonConnectSupportedMessagingContentTypes();
    authenticateAmazonConnect();
  }, []);

  useEffect(() => {
    setFetchIntentResponses(true);
  }, []);

  useEffect(() => {
    if (allIntentsResponseJSON.length && document.getElementById('amazon-connect-chat-widget')) {
      (document.getElementById('amazon-connect-chat-widget') as HTMLElement).style.display = 'block';
    }
  }, [allIntentsResponseJSON]);


  return !allIntentsResponseJSON.length ? (
    <Fragment>
      <div className="Container" id="chat">
        <ContainerVA />
      </div>
      <div
        className="BottomTxtBar"
        // $widthSize={isDesktop && !isChatBot ? '100%' : '100%'}
        style={
          isDesktop && !isChatBot ? { maxWidth: "100%" } : { maxWidth: "665px" }
        }
      >
        <Form
          onSubmit={isRetrying === 0 ? proxySubmitUserText : proxyRetrySubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormSpy>
                {({ form }) => {
                  formRef.current = form;
                  return null;
                }}
              </FormSpy>
              <textarea
                className="InputPrompt"
                placeholder="Ask anything..."
                required
                name="message"
                data-testid="virtualAssistantTextField"
                value={userTxt}
                onKeyPress={onTextAreaKeyPress}
                onChange={(e: any) => setUserTxt(e.target.value)}
              />
              <button
                id="chat-submit"
                className="Send button"
                data-testid="virtualAssistantSendButton"
                type="submit"
                disabled={isDisabled}
              >
                <SendIcon color="#4B4A4A" />
              </button>
            </form>
          )}
        />
      </div>
      <PlaidDialog
        onClose={handleClosePlaid}
        aria-labelledby="plaid-dialog-title"
        open={openPlaid}
        sx={{ margin: 'auto'}}
      >
        <PlaidDialogContent setOpenPlaid={setOpenPlaid} />
      </PlaidDialog>
    </Fragment>
    ) : (
    <Fragment>
      <LoaderScreen />
      {/* {fetchIntentResponses && <FetchIntentResponses setFetchIntentResponses={setFetchIntentResponses} setAllIntentsResponseJSON={setAllIntentsResponseJSON} />} */}
    </Fragment>
    );
};

const denoteAsBot = (item: VAResponseReply) => ({ ...item, from: "bot" });